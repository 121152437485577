import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Layout from '../components/Layout'
import TopicsRoll from '../components/TopicsRoll'
import BlogRoll from '../components/BlogRoll'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const styles = {
  fullwidthimage: {
    width: '100%',
    height: 400,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headertext: {
    color: '#000',
    display: 'flex',
    lineHeight: '1',
    justifyContent: 'space-around',
    flexDirection: 'column',
    textAlign: 'center',
    width: 580,
    maxWidth: '90%',
    background: '#ffffff94',
    padding: 18,
  },
  main: {
    color: '#000',
    marginTop: 12,
    padding: 12,
  },
  section: {
    padding: '30px 0',
    borderBottom: '1px solid #ddd',
  },
  img: {
    width: 500,
    textAlign: 'center',
  }
};

export const IndexHeader = ({
    classes,
    image,
    title,
    subtitle,
  }) => (
      <div>
        <div
          className={classes.fullwidthimage}
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp
                ? image.childImageSharp.fluid.src
                : image
            })`,
            backgroundPosition: `top left`,
            backgroundAttachment: `fixed`,

          }}
        >
          <div className={classes.headertext}>
            <Typography variant="h4" color="inherit" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1" color="inherit" gutterBottom>
              {subtitle}
            </Typography>
          </div>
        </div>
      </div>
    );

IndexHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export const IndexPageTemplate = ({
    classes,
    image,
    title,
    subtitle,
    description,
    main,
  }) => (
      <main className={classes.main}>
        <section className={classes.section}>
          <Typography variant="h2" color="inherit" gutterBottom>
            {main.title}
          </Typography>
          <Typography variant="h6" color="inherit" gutterBottom>
            {main.subtitle}
          </Typography>
          <Typography variant="subtitle1" color="inherit" gutterBottom>
            {main.description}
          </Typography>
          <div className={classes.img}>
            <PreviewCompatibleImage imageInfo={main.image1} />
          </div>
          <p>
            {main.text}
          </p>
        </section>

        <section className={classes.section}>
          <Typography variant="h4" color="inherit" gutterBottom>
            What is a literacy plan?
          </Typography>
          <Button variant="contained" color="default" className={classes.button} component={Link} to="/definition">
            Read more
          </Button>
        </section>

        <section className={classes.section}>
          <Typography variant="h4" color="inherit" gutterBottom>
            Topics
          </Typography>
          <TopicsRoll />
          <Button variant="contained" color="default" className={classes.button} component={Link} to="/topics">
          See all topics
          </Button>
        </section>

        <section className={classes.section}>
          <Typography variant="h4" color="inherit" gutterBottom>
            Latest stories
          </Typography>
          <BlogRoll />
          <Button color="primary" className={classes.button} component={Link} to="/blog">
            Read more
          </Button>
        </section>
      </main>
    );

IndexPageTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  main: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const IndexPage = ({ classes, data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      header={
        <IndexHeader
          classes={classes}
          image={frontmatter.image}
          title={frontmatter.title}
          subtitle={frontmatter.subtitle}
          description={frontmatter.description}
          main={frontmatter.main}
        />
      }
      content={
        <IndexPageTemplate
          classes={classes}
          image={frontmatter.image}
          title={frontmatter.title}
          subtitle={frontmatter.subtitle}
          description={frontmatter.description}
          main={frontmatter.main}
        />
      }
    />
  )
}

IndexPage.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withStyles(styles)(IndexPage);


export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
        description
        main {
          title
          subtitle
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
      }
    }
  }
`
